@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
.btn.nav-btn:hover {
  background-color: var(--color35) !important;
  color: #fff !important;
  /* border: 1px solid var(--color29); */
}
.btn.login-btn:hover {
  background-color: var(--color29) !important;
  color: var(--defualt-white) !important;
}
.dropdown {
  position: static !important;
}
.dropdown-toggle::after {
  display: none;
}
.nav-pad {
  padding: 40px 60px;
}
.nav-pad-x {
  padding: 0 60px;
}
.nav-pad-y {
  padding: 40px 0;
}
.nav-link:focus-visible {
  box-shadow: none;
}
#nav-section{
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  /* box-shadow: 7px 10px 40px 0 rgba(0,0,0,.14) ; */
  box-shadow: 7px 0px 5px 0 rgba(0,0,0,.14) ;

}
.nav-sub-bold{
  font-family: "in_bold" !important;
}
@media only screen and (max-width: 1200px) {
  .nav-mar-collapse {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .nav-item-mar {
    margin: 5px 0;
  }
  .nav-link-font {
    font-family: "in_med" !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .nav-link-font.show {
    border-bottom: 1px solid var(--color33) !important;
  }
  .nav-pad {
    padding: 0;
  }
  .nav-pad-x {
    padding: 0;
  }
  .nav-pad-y {
    padding: 0;
  }
  .li-title {
    font-size: var(--fs-16);
  }
  .li-link {
    font-size: var(--fs-14);
  }
  .dropdown-menu-shadow {
    box-shadow: none;
  }
  .r-vh {
    height: fit-content !important;
  }
}
@media only screen and (max-width: 576px) {
  .r-vh {
    height: 100% !important;
  }
}
.accordion-button.accordion-offcanva-button {
  box-shadow: none;
}
.accordion-button-collapsed {
  color: var(--color28) !important;
}
/* .accordion_button_focus:focus {
  border-bottom: 1px solid var(--color33) !important;
} */
.accordian-pad {
  padding: 1rem 0rem;
}
/* :not(.btn-check) + .btn:active {
  border: none !important;
} */
.btn_close {
  box-shadow: none !important;
}
.z-index-100 {
  z-index: 100 !important;
}
.btn_outline {
  border: none;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown_menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.nav_item.dropdown:hover .dropdown-toggle i {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}
.li-link:hover{
  color: var(--color1) !important;
}
.nav-item:hover > .nav-link {
  font-weight: bold;
}
.slide-transition:hover{
  transform: translateX(10px);
}
@media only screen and (max-width:576px) {
  .navbar_brand{
    width: 30%;
  }
  .btn-font-12{
    font-size: 12px;
  }
}