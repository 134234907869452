.title {
	color: #404341;
	font-size: 34px;
	font-style: normal;
	font-weight: 800;
	line-height: 150%;
}
.lh-21 {
	line-height: 21px;
}
.lh-19 {
	line-height: 19px;
}
.subtitle {
	color: #404341;
	font-size: 14px;
}

.link-color1 {
	color: #404341;
}

.dropdown-menu {
	position: absolute;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #ccc;
	overflow-y: auto;
	z-index: 1;
	width: 100%;
	border-radius: 4px;
}

.suggestion-item {
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.no-results-message {
	color: #777;
}

.clear-all {
	z-index: 1 !important;
}
@keyframes ellipsis {
	0% {
		content: '';
	}
	33% {
		content: '.';
	}
	66% {
		content: '..';
	}
	100% {
		content: '...';
	}
}

.search-result-message::after {
	content: '';
	animation: ellipsis 1.5s infinite;
}

.custom-scroll {
	max-height: 200px;
	overflow-y: auto;
	padding-right: 10px;
	background-color: white;
}

.custom-scroll::-webkit-scrollbar {
	width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
	background: white;
	border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.custom-scroll {
	scrollbar-width: thin;
	scrollbar-color: #888 white;
}

.custom-scroll::-webkit-scrollbar-corner {
	background-color: white;
}
.highlightedCategoryColor{
	background-color: #FDF884 !important;
	color: var(--color28) !important ;
}