.btn.ticket-btn:hover {
  background-color: var(--color29) !important;
  color: var(--defualt-white) !important;
  border: 1px solid var(--color29) !important;
}
@media only screen and (max-width: 768px) {
  .about-text {
    font-size: var(
      --fs-24
    ) !important; /* Fallback to 24px if the custom property is not available */
  }
}
@media only screen and (max-width: 385px) {
  .ticket-btn{
    font-size: var(--fs-14) !important;
  }
}