.active-bg {
  background-color: #ebf9f9;
}
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  scroll-behavior: smooth;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 5px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

.horizontal-scroll a {
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s ease;
}
/* 
.horizontal-scroll a:hover {
  transform: scale(1.05);
} */
.horizontal-scroll-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}
