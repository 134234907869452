.video-section-iframe-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
}
.carousel-control-prev,
.carousel-control-next{
	width: 2%;
}
.carousel-control-prev {
	left: -2%;
}

.carousel-control-next {
	right: -2%;
}

.custom-prev-icon {
	width: 9px;
	height: auto;
	position: absolute;
}

.custom-next-icon {
	width: 9px; 
	height: auto;
	position: absolute;
}

@media only screen and (max-width: 576px) {
	/* .video-height-mobile {
		height: 190px !important;
	} */
	/* .video-section-iframe-container {
		padding-top: 44.25%;
	} */
	.carousel-control-prev {
		left: -5%;
	}
	
	.carousel-control-next {
		right: -5%;
	}
	
}
@media only screen and (max-width: 520px) {
	.video-slider-title {
		margin-top: 1.2rem !important;
	}
}
@media only screen and (max-width: 510px) {
	.video-slider-title {
		margin-top: 1.8rem !important;
	}
}
@media only screen and (max-width: 510px) {
	.video-slider-title {
		margin-top: 2.3rem !important;
	}
}
@media only screen and (max-width: 465px) {
	.video-slider-title {
		margin-top: 3rem !important;
	}
}
@media only screen and (max-width: 430px) {
	.video-slider-title {
		margin-top: 4rem !important;
	}
}
@media only screen and (max-width: 400px) {
	.video-slider-title {
		margin-top: 5rem !important;
	}
}
@media only screen and (max-width: 360px) {
	.video-slider-title {
		margin-top: 6rem !important;
	}
}
@media (min-width: 1200px) {
	.slick-track{
		margin: 0 !important;
	}
}
