.slick-prev:before {
	content: '' !important;
	background-image: url('../../../public/images/video-section/arrow-left.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 9px;
	height: 18px;
	display: block;
}
.slick-next:before {
	content: '' !important;
	background-image: url('../../../public/images/video-section/arrow-right.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 9px;
	height: 18px;
	display: block;
}
.video-container {
	width: 100%;
	position: relative;
}
.custom-play-button {
	width: 36px;
	height: 36px;
	cursor: pointer;
	top: 50%;
}
video {
	cursor: pointer;
}
.view-video-btn-border {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 2px solid var(--color1) !important;
}
@media only screen and (max-width: 576px) {
	.video-section-title {
		font-size: var(--fs-24) !important;
	}
	.video-section-subtitle {
		font-size: var(--fs-14) !important;
	}
}
/* @media only screen and (max-width: 400px) {
	.custom-play-button {
		top: 70%;
	}
} */
