.sidebar-accordion-button::after {
  content: none !important;
}
.sidebar-accordion-button::before {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: 10px !important;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.sidebar-accordion-button:not(.collapsed)::before {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.subContent-accordion_button:not(.collapsed) {
  /* background-color: #E6F6DE !important; */
  border-radius: 8px;
  color: #2b948f !important;
}

.accordion_button:not(.collapsed) {
  color: #2b948f !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.link-hover:hover {
  color: var(--color1) !important;
}
@media only screen and (max-width: 768px) {
  .sidebar-accordion-button::before {
    margin-right: 5px !important;
  }
}
