a {
  text-decoration: none;
}
.demo{
  background: #cef4e3;
  border-radius: 40px;
  padding: 10px;
  width: 40px;
  height: 40px;
  float: right;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.text-size {
  font-size: 13px;
  padding-top: 4px;
}

.text-size:hover {
  color: #2b948f;
}

.color27:hover{
  color : #2b948f
}

img.home-hover:hover {
  content: url(../../../public/images/header-images/home-hover.svg);
}

img.about-hover:hover {
  content: url(../../../public/images/header-images/about-hover.svg);
}
img.broking-hover:hover {
  content: url(../../../public/images/header-images/broking-hover.svg);
}
img.delta-hover:hover {
  content: url(../../../public/images/header-images/delta-hover.svg);
}
img.services-hover:hover {
  content: url(../../../public/images/header-images/services-hover.svg);
}
img.events-hover:hover {
  content: url(../../../public/images/header-images/events-hover.svg);
}
img.contact-hover:hover {
  content: url(../../../public/images/header-images/contact-hover.svg);
}
img.support-hover:hover {
  content: url(../../../public/images/header-images/support-hover.svg);
}


.menu_dropdown_toggle:after {
  margin-left: 1.255em !important;
  border-top: unset !important;
  border-right: unset !important;
  border-bottom: unset !important;
  border-left: unset !important;
}

.dropdown-item:hover{
  background-color: white;
}

.dropdown-menu1{
    width: 50px;
    position: absolute;
    right: -12px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    /* max-height: 200px; */
    overflow-y: auto;
    z-index: 1;
    width: 456px;
    border-radius: 4px;
}
.bottom-style{
  position: fixed;
  z-index: 1;
  width: 100% !important;
  left: 0;
  bottom: 0;
  padding-left: 0 ;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: blur(20px);
  padding-bottom: 10px;
  padding-top: 10px
}



