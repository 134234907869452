.demat-banner {
  background-image: url("/public/images/demat-section/Demat-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
}
.form-control.bs-none:focus {
  box-shadow: none;
  border: 1px solid #fff;
}
.form-control.bs-none::placeholder {
  color: #fff;
  opacity: 1;
}
.pip-border {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}
.btn.demat-btn:hover {
  background-color: var(--color36) !important;
}
@media only screen and (max-width: 576px) {
  .demat-title {
    font-size: var(--fs-24) !important;
    line-height: 1.5 !important;
  }
  .demat-head {
    font-size: var(--fs-18) !important;
  }
  .demat-style-btn{
    width: 100% !important;
    margin-top: 15px;
  }
}
