input[type='radio']:checked + label {
	color: var(--color28) !important;
	background-color: #e6f5de !important;
	border-color: #8c94a6 !important;
	font-family: 'ur_bold';
}

input[type='radio']:not(:checked) + label {
	color: #8c94a6;
	background-color: white;
	border-color: #8c94a6;
	font-family: 'ur_med';
}
input[type='radio']:not(:checked) + label:hover {
	color: var(--color28);
	background-color: white;
	border-color: #8c94a6;
	font-family: 'ur_med';
}
