.decoration {
  color: #404341;
  font-size: 13px;
  padding: 0px 0px 15px;
  font-family: "Bw Modelica SS01", sans-serif;
}

ul li h6 {
  color: #62605a;
  font-size: 13x;
  margin-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
}

/* p {
  font-size: 13px;
} */

.footer-n-style {
  margin-top: -28px !important;
}
.accordion-ftr-button:focus {
  border: none;
  box-shadow: none;
}
.accordion-ftr-button:not(.collapsed) {
  background-color: var(--color32);
  border: none;
  color: var(--color1) !important;
}
.footer-desc {
  font-family: "in_reg";
  font-size: var(--fs-12);
  line-height: 16px;
}
.footer-bg {
  background-image: url("/public/images/footer-images/jainamSign.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.footer-link:hover{
  color: var(--color1) !important;
  transition: .2s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .custom-footer-width {
    width: 100% !important;
  }
}
