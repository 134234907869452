.effect:hover .effect-s {
  transition: transform 0.3s ease-in-out !important;
  transform: scale(1.5, 1.5) !important;
}

.effect-s {
  transition: transform 0.3s ease-in-out;
}

.htmlcontent p {
  padding: 1px !important;
  margin: 0px !important;
}

.reshtml ul,
.reshtml ul li,
.reshtml table,
.reshtml ol li,
.reshtml tbody {
  line-height: 22px !important;
}
.youtext {
  font-size: 16px;
  line-height: 30px;
  color: rgb(68, 68, 68);
}

.youtext-res {
  font-size: 16px;
  line-height: 30px;
  color: rgb(68, 68, 68);
}
/* div > p > span > em > strong,
div > h1 > span > strong > em {
  color: var(--color1);
  font-family: "ur_ebold";
  font-size: var(--fs-40);
  font-style: normal;
  font-weight: 800;
}
li > a > span {
  color: var(--color1);
  cursor: pointer;
  font-family: "in_med";
  font-size: var(--fs-16) !important;
} */
/* .child-cat-title > p > span ,
.child-cat-title > div > p > span {
  color: var(--color1) !important;
  font-family: "ur_ebold";
  font-size: var(--fs-40) !important;
  font-style: normal !important;
  font-weight: 800;
  line-height: 48px !important;
} */
em {
  font-style: normal !important;
}
.child-cat-title{
  margin-bottom: 12px;
}
// .child-cat-title > p > span,
// .child-cat-title > p > strong > em > span,
// .child-cat-title > p > span > strong > em > span,
// .child-cat-title > p > span > strong > span > em,
// .child-cat-title > p > span > em > strong > span,
// .child-cat-title > p > span > em > span > strong,
// .child-cat-title > p > span > em > span > strong > span,
// .child-cat-title > div > span > em > span > strong > span,
// .child-cat-title > p > em > strong > span,
// .child-cat-title > p > em > span > strong,
// .child-cat-title > .ql-editor.read-mode > p > span > strong,
// .child-cat-title > .ql-editor.read-mode > p > span > em > strong,
// .child-cat-title > .ql-editor.read-mode > p > span > em > span > strong,
// .child-cat-title > .ql-editor.read-mode > p > span > strong > em > span,
// .child-cat-title > .ql-editor.read-mode > p > span > strong > span > em,
// .child-cat-title > .ql-editor.read-mode > p > em > span > strong,
// .child-cat-title > .ql-editor.read-mode > p > strong > em > span,
// .ql-editor.read-mode > p > strong > em,
// .child-cat-title > div > em > strong > span,
// .child-cat-title > div > span > em > strong,
// .child-cat-title > div > p > span > strong > em,
// .child-cat-title > h1 > span > strong,
// .child-cat-title > ol > li > span > em > strong,
// .child-cat-title > ol > li > span > strong > em,
// .child-cat-title > .ql-editor.read-mode > ol > li > span > strong > em,
// .child-cat-title > p > span > strong > em
// {
//   color: var(--color1) !important;
//   font-family: "ur_ebold";
//   font-size: var(--fs-28) !important;
//   font-style: normal !important;
//   font-weight: 800;
//   line-height: 35px !important;
// }
.child-cat-title {
  p ,span, strong, em, u {
    color: var(--color1) !important;
    font-family: "ur_ebold" !important;
    font-size: var(--fs-28) !important;
    font-style: normal !important;
    font-weight: 800;
    line-height: 35px !important;
  }
}


.child-cat-subtitle > p,
.child-cat-subtitle > ul {
  font-family: "in_reg" !important;
  color: var(--color28) !important;
  line-height: 24px;
  font-size: 16px;
}
.child-cat-subtitle > ul > li > span,
.child-cat-subtitle > ul > li > ol > li,
.child-cat-subtitle > ul > li > ol > li > span ,
.child-cat-subtitle > div > pre > span{
  color: #444 !important;
  font-family: "in_reg" !important;
  line-height: 24px;
  font-size: 18px !important;
}
u > span,
u > a , 
strong > a{
  color: var(--color1) !important;
  text-decoration: none !important;
}
a > u{
  text-decoration: none !important;
}
.child-cat-subtitle > ol > li > ol > li {
  color: #444 !important;
  font-family: "in_med" !important;
  line-height: 26px;
  font-size: 16px !important;
}
.child-cat-subtitle > ol > li > ol > .\31 5 {
  color: var(--color28) !important;
  font-family: "in_med" !important;
  line-height: 24px;
  font-size: 16px !important;
}
.child-cat-subtitle > ol > li > ol > li > span {
  color: #444 !important;
  font-family: "in_reg" !important;
  line-height: 24px;
  font-size: 18px !important;
}
a > span,
span > a  {
  color: var(--color1) !important;
  font-family: "in_med" !important;
  line-height: 24px;
  font-size: 16px !important;
}
td > strong{
  color: var(--color28) !important;
  font-family: "in_med" !important;
  line-height: 24px;
  font-size: 16px !important;
}
ul > li > span,
li > span,
li > span > span,
li > span > em,
p > span,td > span,
.child-cat-subtitle > ul > li > span > span{
  color: #444 !important;
  font-family: "in_reg" !important;
  line-height: 24px;
  font-size: 18px !important;
  background-color: transparent !important;
}
td {
  padding: 15px;
  line-height: 24px;
  text-align: start !important;
  font-size: 16px !important;
  font-family: "in_reg" !important;
}
/* ul,
ol {
  list-style-type: none;
  padding: 0;
} */
/* strong{
  font-family: "in_semi";
} */
li {
  font-size: 16px !important;
}
.child-cat-subtitle > strong {
  color: red;
}
.child-cat-subtitle > p > strong > span {
  color: var(--color28) !important;
  font-family: "in_med";
  line-height: 24px;
  font-size: 16px !important;
}
.child-cat-subtitle > div > span {
  color: var(--color28) !important;
  font-family: "in_reg" !important; 
  line-height: 24px;
  font-size: 16px !important;
}
td > ul ,td > ol{
  margin-bottom: 0;
}
.video-height{
  max-height: 400px;
  max-width: 100%;
}
.faq-announcement-table-title{
  font-family: "in_bold";
  font-size: var(--fs-24);
  color: #fff !important;
  background-color: var(--color1) !important;
  padding: 4px 24px !important;
}
.faq-announcement-table-content{
  font-family: "in_reg";
  font-size: var(--fs-18);
  color: #1A1A1A !important;
  font-weight: 500;
  background-color: var(--color8) !important;
  padding: 16px 16px 8px 16px !important;
}
.faq-announcement-table-content > ul > li{
  font-size: var(--fs-18) !important;
  color: #1A1A1A !important;
  font-weight: 500;
  background-color: var(--color8) !important;
}
.announcement-table {
  border-radius: 16px;
  overflow: hidden; 
  tr {
    border: 0 !important; 
  }
}


@media only screen and (max-width: 768px) {
  .child-cat-title > p > span,
  .child-cat-title > p > strong > em > span,
  .child-cat-title > p > span > strong > em > span,
  .child-cat-title > p > span > strong > span > em,
  .child-cat-title > p > span > em > strong > span,
  .child-cat-title > p > span > em > span > strong,
  .child-cat-title > p > span > em > span > strong > span,
  .child-cat-title > div > span > em > span > strong > span,
  .child-cat-title > p > em > strong > span,
  .child-cat-title > p > em > span > strong,
  .child-cat-title > .ql-editor.read-mode > p > span > strong,
  .child-cat-title > .ql-editor.read-mode > p > span > em > strong,
  .child-cat-title > .ql-editor.read-mode > p > span > em > span > strong,
  .child-cat-title > .ql-editor.read-mode > p > span > strong > em > span,
  .child-cat-title > .ql-editor.read-mode > p > span > strong > span > em,
  .child-cat-title > .ql-editor.read-mode > p > em > span > strong,
  .child-cat-title > .ql-editor.read-mode > p > strong > em > span,
  .ql-editor.read-mode > p > strong > em,
  .child-cat-title > div > em > strong > span,
  .child-cat-title > div > span > em > strong,
  .child-cat-title > div > p > span > strong > em,
  .child-cat-title > h1 > span > strong,
  .child-cat-title > ol > li > span > em > strong,
  .child-cat-title > ol > li > span > strong > em,
  .child-cat-title > .ql-editor.read-mode > ol > li > span > strong > em,
  .child-cat-title > p > span > strong > em {
    font-size: var(--fs-24) !important;
    line-height: 32px !important;
  }
  .video-height{
    max-height: 600px;
    max-width: 100%;
  }
}
.lightbox-image{
   width: auto; 
  max-width: 100%;
  height: auto; 
}
.imageModal._1ek8k._z-bHV._21mY1 {
  height: 100vh !important;
  padding: 5rem 0;
}
.child-cat-subtitle table{
  background-color: rgb(230, 246, 222) !important;
  border-color: #44444447 !important;
  width: 100% !important;
  margin: 15px 0;
}
tr, td{
  height: 0 !important;
  border: 1px solid #44444447 !important;
}
.child-cat-subtitle{
  li{
    font-family: "in_reg" !important;
    color: #444 !important;
  }
}
.yt-container {
  #player > .html5-video-player > .ytp-cued-thumbnail-overlay > .ytp-cued-thumbnail-overlay-image{
          background-size: contain !important;
          -webkit-background-size: contain !important;
  }
}
span, strong,strong > em{
  background-color: transparent !important;
  // color: #444 !important;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width:576px) {
  .faq-announcement-table-title{
    font-size: var(--fs-20);
  }
}
.child-cat-subtitle table tr:first-of-type td{
  font-family: "in_bold";
  color: #fff !important;
  // background-color: var(--color1) !important;
  background-color: var(--color8) !important;

}

.child-cat-subtitle table tr:nth-of-type(n+2) td {
  font-family: "in_reg";
  color: #1A1A1A !important;
  font-weight: 500;
  background-color: var(--color8) !important;
}
