.b1:hover{
    /* background-color: #E6F6DE !important; */
    border-radius: 8px;
}

.loader3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bars {
    width: 4px;
    height: 12px;
    margin: 0 2px;
    border-radius: 4px;
    animation: loader3 3s ease-in-out infinite;
  }
  
  .bar1 {
    background-color: #3b814c;
    animation-delay: -0.8s;
  }
  
  .bar2 {
    background-color: #3b814c;
    animation-delay: -0.7s;
  }
  
  .bar3 {
    background-color: #3b814c;
    animation-delay: -0.6s;
  }
  
  .bar4 {
    background-color: #3b814c;
    animation-delay: -0.5s;
  }
  
  .bar5 {
    background-color: #3b814c;
    animation-delay: -0.4s;
  }
  
  .bar6 {
    background-color: #3b814c;
    animation-delay: -0.3s;
  }
  
  .bar7 {
    background-color: #3b814c;
    animation-delay: -0.2s;
  }
  
  .bar8 {
    background-color: #3b814c;
    animation-delay: -0.1s;
  }
  
  .bar9 {
    background-color: #3b814c;
    animation-delay: 0s;
  }
  
  .bar10 {
    background-color: #3b814c;
    animation-delay: 0.1s;
  }
  
  @keyframes loader3 {
    0% {
      transform: scale(1);
    }
  
    20% {
      transform: scale(1, 2.32);
    }
  
    40% {
      transform: scale(1);
    }
  }
  