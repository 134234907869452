.sidebar-accordion-button::after {
  content: none !important;
}
.sidebar-accordion-button::before {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: 0px !important;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.sidebar-accordion-button:not(.collapsed)::before {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.subContent-accordion_button:not(.collapsed) {
  background-color: transparent !important;
  /* border-radius: 8px; */
  color: var(--color28) !important;
}

.accordion_button:not(.collapsed) {
  color: #2b948f !important;
}
