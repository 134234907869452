@media only screen and (max-width: 768px) {
	.r-lh {
		line-height: 1;
	}
}
.breadcrumb-item + .breadcrumb-item,
.breadcrumb-item + .breadcrumb-item::before {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
