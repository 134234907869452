.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-heading {
    font-size: 3rem;
    color: #d9534f;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    color: #333;
  }