.video-section-modal {
	width: 595px;
	height: 335px;
}
.video-modal-btn-close:focus{
box-shadow: none;
}
@media only screen and (max-width: 992px) {
	.video-section-modal {
		width: 395px;
		height: 222px;
	}
}
@media only screen and (max-width: 576px) {
	.video-section-modal {
		width: 100%;
		height: 181px;
	}
	.video-modal-dialog-centered {
		justify-content: center;
	}
	/* iframe {
		min-width: 321px;
	} */
}
