.b1:hover {
  /* background-color: #E6F6DE !important;  */
  border-radius: 8px;
}

.loader3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bars {
  width: 4px;
  height: 12px;
  margin: 0 2px;
  border-radius: 4px;
  animation: loader3 3s ease-in-out infinite;
}

.bar1 {
  background-color: #3b814c;
  animation-delay: -0.8s;
}

.bar2 {
  background-color: #3b814c;
  animation-delay: -0.7s;
}

.bar3 {
  background-color: #3b814c;
  animation-delay: -0.6s;
}

.bar4 {
  background-color: #3b814c;
  animation-delay: -0.5s;
}

.bar5 {
  background-color: #3b814c;
  animation-delay: -0.4s;
}

.bar6 {
  background-color: #3b814c;
  animation-delay: -0.3s;
}

.bar7 {
  background-color: #3b814c;
  animation-delay: -0.2s;
}

.bar8 {
  background-color: #3b814c;
  animation-delay: -0.1s;
}

.bar9 {
  background-color: #3b814c;
  animation-delay: 0s;
}

.bar10 {
  background-color: #3b814c;
  animation-delay: 0.1s;
}

@keyframes loader3 {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.32);
  }

  40% {
    transform: scale(1);
  }
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%280, 0, 0, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler[data-bs-state="open"] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%280, 0, 0, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M6 6L24 24M24 6L6 24'/%3E%3C/svg%3E");
}
